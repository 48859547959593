import { get, post, del } from 'utilihive-http';
import { stringify } from 'qs';
import { taskManager } from '../../config/backendRoutes';

const stringifyQuery = params =>
  params ? stringify(params, { skipNulls: true, addQueryPrefix: true, encode: false }) : '';

const task = (organizationShortName, taskId) => `${taskManager}/${organizationShortName}/v1/tasks/${taskId}`;
const tasks = (organizationShortName, queryParams) =>
  `${taskManager}/${organizationShortName}/v1/tasks${stringifyQuery(queryParams)}`;
const taskComment = (organizationShortName, taskId, commentId) =>
  `${taskManager}/${organizationShortName}/v1/tasks/${taskId}/comments/${commentId}`;
const taskComments = (organizationShortName, taskId, queryParams) =>
  `${taskManager}/${organizationShortName}/v1/tasks/${taskId}/comments${stringifyQuery(queryParams)}`;

export const fetchTasksAssignedToUser = (token, organizationShortName, userId, page, size) =>
  get(
    tasks(organizationShortName, {
      page,
      size,
      assignee: userId,
    }),
    { token }
  );

export const getTask = (token, organizationShortName, taskId) => get(task(organizationShortName, taskId), { token });
export const createTask = (token, organizationShortName, task) =>
  post(tasks(organizationShortName), { token, body: task });
export const updateTask = (token, organizationShortName, taskId, taskData) =>
  post(task(organizationShortName, taskId), { token, body: taskData, parseResult: false });
export const deleteTask = (token, organizationShortName, taskId) => del(task(organizationShortName, taskId), { token });

export const getTaskComments = (token, organizationShortName, taskId, page, size) =>
  get(taskComments(organizationShortName, taskId, { page, size }), { token });
export const addTaskComment = (token, organizationShortName, taskId, content) =>
  post(taskComments(organizationShortName, taskId), { token, body: content });
export const updateTaskComment = (token, organizationShortName, taskId, commentId, content) =>
  post(taskComment(organizationShortName, taskId, commentId), { token, body: content });
export const deleteTaskComment = (token, organizationShortName, taskId, commentId) =>
  del(taskComment(organizationShortName, taskId, commentId), { token });
